const scaleBtn = document.querySelector(".apartment-info .scale");

if (scaleBtn) {
  const modal = document.querySelector(".apartment-info .floorplan-modal");

  scaleBtn.addEventListener("click", function () {
    if (modal.matches(".scale")) {
      modal.classList.remove("scale");

      setTimeout(function () {
        modal.style.display = "none";
      }, 300);
    } else {
      modal.style.display = "block";
      setTimeout(function () {
        modal.classList.add("scale");
      }, 300);
    }
  });

  modal.addEventListener("click", function () {
    if (modal.matches(".scale")) {
      modal.classList.remove("scale");
      setTimeout(function () {
        modal.style.display = "none";
      }, 500);
    } else {
      modal.style.display = "block";
      setTimeout(function () {
        modal.classList.add("scale");
      }, 300);
    }
  });
}
