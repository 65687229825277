import 'jquery.scrollto'
import "./_body-view-height";
import "./header";
import "./_slideshow.js";
import "./_featured-block.js";
import "./_block-map.js";
import "./_scroll-to.js";
import "./_search.js";
import "./_scroll-to.js";
import "./_grensesnitt-lightbox.js";

let skipHeader = document.querySelector("a.visually-hidden.skip-to-main");
if (skipHeader != undefined) {
  skipHeader.addEventListener("focusin", function (e) {
    this.classList.add("focused");
  });
  skipHeader.addEventListener("focusout", function (e) {
    this.classList.remove("focused");
  });
}
import "./_menu-on-scroll.js";
import "./_scale-floorplan.js";
import "./_animations.js";

import "./_apartment-units.js";

import './_block-contact-form.js';