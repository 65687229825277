$contactInterestForm = $('.contact-form');
$contactInterestForm.submit(function(e) {
    e.preventDefault();

    submitInterest(e.target);
});

function submitInterest(target) {
    let $form = $(target);
    let formData = $form.serializeArray();
    let $response = $form.find('.response');
    let $button = $form.find('input[type="submit"]');
    let $loadingSpinner = $form.find('.loading-spinner');

    let data = new FormData();
    data.append('action', 'submit_interest');

    for (let i = 0; i < formData.length; i++) {
        let name = formData[i]['name'];
        let value = formData[i]['value'];
        data.append(name, value);
    }

    $button.blur();
    $button.prop('disabled', true);
    $loadingSpinner.addClass('loading');
    $response.removeClass('error success');
    $response.hide();

    $.ajax({
        url: langgatapluss_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function(data) {
            $response.addClass('error');
            $response.html('En ukjent feil oppsto.');
        },
        complete: function(data) {
            try {
                let json = JSON.parse(data.responseText);
                $response.html(json.message);

                if (json.status === 'failed') {
                    $response.addClass('error');
                } else {
                    $form[0].reset();
                    $response.addClass('success');
                }
            }
            catch (error) {
                $response.addClass('error');
                $response.html('En ukjent feil oppsto: ' + error);
            }

            $response.show();
            $button.find('.content').show();
            $button.prop('disabled', false);
            $loadingSpinner.removeClass('loading');
        }
    });
}
