import Swipe from 'swipejs'

var animationLength = 300; // Change this in _grensesnitt-lightbox.scss as well if changed
var $grensesnittLightboxContainer = $('.gl-container');
var $navPrev = $('.gl-nav-prev > a');
var $navNext = $('.gl-nav-next > a');
var swipe;
var $lightboxes = $('.lightbox');

if($lightboxes.length > 0) {

    $lightboxes.click(function (e) {
        e.preventDefault();

        showLightbox();

        if (swipe) {
            swipe.kill();
            swipe = null;
        }

        var $lightboxImages = $(this).closest('.image-gallery').find('a');
        $lightboxImages.each(function (index, el) {
            var $el = $(el);
            $el.attr('data-index', index);
        });

        addImages($lightboxImages);

        swipe = new Swipe($('.gl-swipe')[0], {
            draggable: true,
            continuous: true,
            disableScroll: true,
            transitionEnd: function(index, elem) {
                let $elem = $(elem);
            },
        });

        $grensesnittLightboxContainer[0].addEventListener('touchmove', function (e) {
            e.preventDefault();
        });

        var index = parseInt($(this).data('index')) || 0;
        swipe.slide(index, 1);
    });

    $('.gl-container .gl-close').click(function (e) {
        e.preventDefault();      
        hideLightbox();
    });

    document.querySelector('body').addEventListener('keydown', (event) => {
        if($('.gl-container').hasClass('show')) {
            if(event.code == 'ArrowLeft') {
                swipe.prev();
            }
            else if(event.code == 'ArrowRight') {
                swipe.next();
            }
            else if(event.code == 'Escape') {
                hideLightbox();
            }       
        }
    });

    $navNext.click(function (e) {
        e.preventDefault();
        swipe.next();
    });

    $navPrev.click(function (e) {
        e.preventDefault();
        swipe.prev();
    });

    $(document).click(function (e) {
        let $target = $(e.target);
        if (e.target.tagName === 'IMG') {
            return;
        }
        else if($target.closest('.gl-inner').length && $grensesnittLightboxContainer.hasClass('show')) {
            return;   
        }
        hideLightbox();
    });

    function addImages($lightboxImages) {
        var $swipeWrap = $('.gl-swipe-wrap');
        $swipeWrap.html('');
        
        $lightboxImages.each(function (index, el) {
            var $el = $(el);
            var url = $el.find('img').attr('src');
            var alt = $el.find('img').attr('alt');
            $swipeWrap.append('<div><div class="image"><img src="' + url + '" alt="' + alt + '" /></div></div>');
        })
    }

    function hideLightbox() {
        $grensesnittLightboxContainer.removeClass('fade');
        $('header').removeClass('lightbox-open');

        setTimeout(function () {
            $grensesnittLightboxContainer.removeClass('show');
            $('body').removeClass('no-scroll');
        }, animationLength);
    }

    function showLightbox() {
        $grensesnittLightboxContainer.addClass('show');

        setTimeout(function () {
            $grensesnittLightboxContainer.addClass('fade');
            $('header').removeClass('show');

            setTimeout(function () {
                $('body').addClass('no-scroll');
            }, animationLength);
        }, 10);
    }
}