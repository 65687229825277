import { intersectionObserve } from "./_intersection-observer";

const elements = document.querySelectorAll(".featured a");

intersectionObserve(elements, 0, 0, (element, intersectionRatio) => {
  (function (el) {
    if (intersectionRatio > 0 && !el.classList.contains("fade-in")) {
      el.classList.add("fade-in");
    }
  })(element);
});
