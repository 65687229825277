import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

if ($('.ease-in').length > 0) {
  gsap.set('#content-section section .ease-in', { autoAlpha: 0, y: '1.5rem' });
  ScrollTrigger.batch('.ease-in', {
    interval: 0.3, // time window (in seconds) for batching to occur.
    batchMax: 4, // maximum batch size (targets)
    onEnter: (batch) =>
      gsap.to(batch, { y: 0, autoAlpha: 1, stagger: 0.25, overwrite: true }),
    start: 'top+=100px bottom',
  });
}
if ($('.ease-in-content p').length > 0) {
  gsap.set('#content-section section .ease-in-content p', {
    autoAlpha: 0,
    y: '1.5rem',
  });
  ScrollTrigger.batch('.ease-in-content p', {
    interval: 0.3, // time window (in seconds) for batching to occur.
    batchMax: 4, // maximum batch size (targets)
    onEnter: (batch) =>
      gsap.to(batch, { y: 0, autoAlpha: 1, stagger: 0.25, overwrite: true }),
    start: 'top+=100px bottom',
  });
}
if ($('.ease-in-content h2').length > 0) {
  gsap.set('#content-section section .ease-in-content h2', {
    autoAlpha: 0,
    y: '1.5rem',
  });
  ScrollTrigger.batch('.ease-in-content h2', {
    interval: 0.3, // time window (in seconds) for batching to occur.
    batchMax: 4, // maximum batch size (targets)
    onEnter: (batch) =>
      gsap.to(batch, { y: 0, autoAlpha: 1, stagger: 0.25, overwrite: true }),
    start: 'top+=100px bottom',
  });
}
if ($('.ease-in-content h3').length > 0) {
  gsap.set('#content-section section .ease-in-content h3', {
    autoAlpha: 0,
    y: '1.5rem',
  });
  ScrollTrigger.batch('.ease-in-content h3', {
    interval: 0.3, // time window (in seconds) for batching to occur.
    batchMax: 4, // maximum batch size (targets)
    onEnter: (batch) =>
      gsap.to(batch, { y: 0, autoAlpha: 1, stagger: 0.25, overwrite: true }),
    start: 'top+=100px bottom',
  });
}

if ($('.ease-in-content ul').length > 0) {
  gsap.set('#content-section section .ease-in-content ul', {
    autoAlpha: 0,
    y: '1.5rem',
  });
  ScrollTrigger.batch('.ease-in-content ul', {
    interval: 0.3, // time window (in seconds) for batching to occur.
    batchMax: 4, // maximum batch size (targets)
    onEnter: (batch) =>
      gsap.to(batch, { y: 0, autoAlpha: 1, stagger: 0.25, overwrite: true }),
    start: 'top+=100px bottom',
  });
}
