import { MarkerClusterer } from '@googlemaps/markerclusterer';

let map = null;
let a15Bounds;

const mapIcons = {
  circle:
    '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 118 117.6" style="enable-background:new 0 0 118 117.6;" xml:space="preserve"> <style type="text/css"> .st0{clip-path:url(#SVGID_00000061432586802439709330000010071300394507966863_);fill:#F1719D;} .st1{clip-path:url(#SVGID_00000061432586802439709330000010071300394507966863_);fill:none;stroke:#EB74AD;stroke-width:0.859;} .st2{fill:#FFFFFF;} </style><g><defs><rect id="SVGID_1_" y="-0.4" width="118" height="118"/></defs><clipPath id="SVGID_00000050656112041119784710000001992451959997355162_"><use xlink:href="#SVGID_1_"  style="overflow:visible;"/></clipPath><path style="clip-path:url(#SVGID_00000050656112041119784710000001992451959997355162_);fill:#F1719D;" d="M59,117.1 c32.3,0,58.6-26.2,58.6-58.6S91.3,0,59,0S0.4,26.2,0.4,58.6S26.6,117.1,59,117.1"/><circle style="clip-path:url(#SVGID_00000050656112041119784710000001992451959997355162_);fill:none;stroke:#EB74AD;stroke-width:0.859;" cx="59" cy="58.6" r="58.6"/></g><path class="st2" d="M66.8,26.9H47.6v32.2H26v9.5h21.6v21.6h10.9V68.7h8.3C81.9,68.7,92,60,92,47.8C92,35.4,81.9,26.9,66.8,26.9z M66.6,59.1h-8.1V36.7h8.1c8.6,0,14.5,4,14.5,11.2C81,55.2,75.2,59.2,66.6,59.1z"/></svg>',
};

const styles = [
  {
    featureType: 'administrative.country',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
      {
        lightness: '-72',
      },
      {
        saturation: '93',
      },
      {
        gamma: '6.78',
      },
    ],
  },
  {
    featureType: 'poi.attraction',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },

  {
    featureType: 'poi.business',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  // {
  //   featureType: 'poi.Restaurant',
  //   elementType: 'labels',
  //   stylers: [
  //     {
  //       visibility: 'on',
  //     },
  //   ],
  // },
  {
    featureType: 'poi.government',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.medical',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'all',
    stylers: [
      {
        color: '#24b059',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'poi.place_of_worship',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.school',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.sports_complex',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        gamma: '1.64',
      },
      {
        color: '#efbf15',
      },
    ],
  },
  {
    featureType: 'transit.station.airport',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on'
      }
    ]
  },
  {
    featureType: 'transit.station.bus',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on'
      }
    ]
  },
  {
    featureType: 'transit.station.rail',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#32b1da',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
];

/**
 * initMap
 *
 * Renders a Google Map onto the selected jQuery element
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @return  object The map instance.
 */
function initMap($el) {
  const a15MinSW = new google.maps.LatLng(58.911104, 5.630856);
  const a15MinNE = new google.maps.LatLng(58.822141, 5.813318);
  let lat = parseFloat($el.find('.marker').attr('data-lat')) || 0;
  let lng = parseFloat($el.find('.marker').attr('data-lng')) || 0;
  a15Bounds = new google.maps.LatLngBounds(a15MinSW, a15MinNE);

  const startLocation = {
    lat: lat,
    lng: lng,
  };

  let markers = [];

  // Find marker elements within map.
  const $markers = $el.find('.marker');

  // Create gerenic map.
  const mapArgs = {
    center: startLocation,
    zoom: 17,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles: styles,
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: true,
  };
  map = new google.maps.Map($el[0], mapArgs);

  //map.fitBounds(a15Bounds);

  // Add markers.
  $markers.each(function () {
    const marker = initMarker($(this), map);
    markers.push(marker);
  });

  // Return map instance.
  return map;
}

/**
 * initMarker
 *
 * Creates a marker for the given jQuery element and map.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @param   object The map instance.
 * @return  object The marker instance.
 */
function initMarker($marker) {
  // Get position from marker.
  const lat = $marker.data('lat');
  const lng = $marker.data('lng');
  //   const type = $marker.data("type");
  const name = $marker.data('name');
  const latLng = {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };

  let url = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(mapIcons.circle);
  let scaledSize = new google.maps.Size(50, 50);
  let anchor = null;

  const icon = {
    url: url,
    scaledSize: scaledSize, // scaled size
    optimized: false,
    anchor: anchor,
  };

  // Create marker instance.
  const marker = new google.maps.Marker({
    position: latLng,
    icon: icon,
    map: map,
    /*label: {
            text: name,
            className: 'marker-label'
        },*/
  });

  // If marker contains HTML, add it to an infoWindow.
  if ($marker.html()) {
    // Create info window.
    var infowindow = new google.maps.InfoWindow({
      content: $marker.html(),
    });

    // Show info window when marker is clicked.
    google.maps.event.addListener(marker, 'click', function () {
      infowindow.open(map, marker);
    });
  }

  return marker;
}

window.initMaps = function () {
  // Render maps on page load.
  $(document).ready(function () {
    $('.acf-map').each(function () {
      $(this).addClass('fade-in');
      initMap($(this));
    });
  });
};
