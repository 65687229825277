let $searchIcon = $('header .search-wrapper button.search-btn');
let $searchInput = $('#search #search-input');
let $pageSearchInput = $('#search-page #search-page-input');
let $searchPage = $('body.search');
let $inputValue = $('#content-section #search-page #search-page-input').val();
let $pageInputValue = $('#content-section #search-page #search-page-input').val();

if ($searchIcon) {
    $searchIcon.click(function (e) {
        e.preventDefault();
        $(this).siblings('#search').toggleClass('expand');
        $searchInput.focus();
    });
}

if ($searchPage.length > 0) {
    $('header').addClass('dark-menu');
    $pageSearchInput.focus();
    $pageSearchInput.val('');
    $pageSearchInput.val($inputValue); 
}
