import 'slick-carousel/slick/slick';
import Splitting from "splitting";

function initSlider(id) {
    $('#' + id).slick({
        infinite: true,
        dots: false,
        speed: 700,
        autoplay: true,
        autoplaySpeed: 10000,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: '<button type="button" class="slick-next" aria-label="Next slide"><?xml version="1.0" encoding="UTF-8"?><svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320.01 560"><g><g><path d="M224.19,280L11.89,491.13c-15.86,15.76-15.86,41.3,0,57.05,15.86,15.75,41.57,15.75,57.43,0l238.94-237.63c8.45-8.39,12.25-19.56,11.7-30.55,.56-10.99-3.25-22.16-11.7-30.55L69.33,11.82C53.47-3.94,27.75-3.94,11.89,11.82s-15.86,41.3,0,57.05l212.29,211.13Z"/></g></g></svg></button>',
        prevArrow: '<button type="button" class="slick-prev" aria-label="Previous slide"><?xml version="1.0" encoding="UTF-8"?><svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320.01 560"><g><g><path d="M95.83,280L308.12,68.87c15.86-15.76,15.86-41.3,0-57.05-15.86-15.75-41.57-15.75-57.43,0L11.75,249.45C3.3,257.84-.5,269.01,.05,280c-.56,10.99,3.25,22.16,11.7,30.55l238.94,237.63c15.86,15.76,41.57,15.76,57.43,0s15.86-41.3,0-57.05L95.83,280Z"/></g></g></svg></button>',
    });
    
    let $currentTitle;
    let $slides = $('.slick-slide:not(.slick-cloned)');
    let $firstCurrent = $('.slick-current').find('.title');
    let $beforeSplitting = [];
    $slides.each(function(index) {
        $beforeSplitting[index] = $(this).find('.title').html();
    });
    Splitting({ target: $firstCurrent });

    if($('.slick-current').find('a.btn-link').length > 0) {
        $('.slick-current').find('a.btn-link').addClass('show');
    }
    $('#' + id).on('afterChange', function(event, slick, currentSlide, nextSlide){
        $('.slick-slide').find('a.btn-link').removeClass('show');
        $currentTitle = $('.slick-current').find('.title');

        $slides.each(function(index) {
            if(index != slick.currentSlide) {
                $(this).find('.title').remove();
                $(this).find('.content').prepend('<div class="title" data-splitting>' + $beforeSplitting[index] + '</div>');
            }
        });

        if($('.slick-current').find('a.btn-link').length > 0) {
            $('.slick-current').find('a.btn-link').addClass('show');
        }
        Splitting({ target: $currentTitle });

    });
};
window.initSlider = initSlider;