var $header = $('header');
var $headerHeight = $header.outerHeight();
var headerHeight = $header.height();
var lastScrollTop = 0;

 $(window).scroll(function (e) {
    var scrollTop = $(this).scrollTop();
    if ($(window).width() >= 1024 && scrollTop <= 16) {
        $header.removeClass('sticky show animate');
    } 
    else if (scrollTop === 0) {
        $header.removeClass('sticky show animate');
    } 
    else if (scrollTop > headerHeight) {
        if (scrollTop < lastScrollTop) { // Scrolling up
            $header.addClass('sticky animate show')
        } else {
            $header.addClass('sticky').removeClass('show');
        }
    }
    lastScrollTop = scrollTop;
});